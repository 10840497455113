<template>
  <div class="ml-default-modal">
    <div class="d-flex flex-column align-items-center">
      <img
        width="65"
        class="mb-4"
        height="65"
        src="@/assets/img/check-circle.svg"
        alt=""
      />
      <div
        v-if="text"
        v-html="text"
        class="ml-text-16-24-500 ml-text-grey text-center"
      >
        <!-- {{ text }} -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text']
}
</script>
