import types from './types'
import Constants from '@/helpers/constants'

export default {
  [types.REMOVE_CERTIFICATE_ACTION]({ commit }) {
    commit(types.REMOVE_CERTIFICATE)
    return Promise.resolve()
  },
  [types.ADD_CERTIFICATE_ACTION]({ state, rootState, commit }, item) {
    const maxCount =
      rootState?.app?.pageOrderConfig?.MaxCountPurchases ||
      Constants?.DEFAULTS?.MAX_COUNT_PURCHASE
    commit(types.ADD_CERTIFICATE, { item, maxCount })
  },
  [types.UPDATE_CERTIFICATE_ACTION]({ state, rootState, commit }, item) {
    const maxCount =
      rootState?.app?.pageOrderConfig?.MaxCountPurchases ||
      Constants?.DEFAULTS?.MAX_COUNT_PURCHASE
    commit(types.UPDATE_CERTIFICATE, { item, maxCount })
  }
}
