import axios from 'axios'
import types from './types'
import appTypes from '@/store/app/types'
import YookassaService from '@/api/YookassaService'

export default {
  [types.SITE_PAGE_STATUS_PAYMENT]: async ({
    commit,
    rootState,
    rootGetters
  }) => {
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const sessionid = rootGetters['app/getSessionId']
    const siteCode = rootGetters['app/getSiteCode']
    const theme = rootState?.app?.config?.Theme
    const pageType = rootGetters['app/getMainPage']
    const data = {
      Operator: operator,
      Token: token,
      SessionId: sessionid,
      SiteCode: siteCode,
      PageType: pageType?.Type,
      Theme: theme
    }
    return YookassaService.getSitePageStatusPayment(data).then(response => {
      if (
        response?.status === 200 &&
        response?.data?.ErrorCode === 0 &&
        !response?.data?.Message
      ) {
        console.log('STATYS', response.data)
        commit(
          `app/${appTypes.SET_STATUS_PAYMENT_PAGE_CONFIG}`,
          response?.data,
          { root: true }
        )
      }
    })
  },
  [types.BUY_MORE_ACTION]: async ({ rootState, dispatch }) => {
    const params = {
      code: rootState?.app?.code,
      toMain: true
    }
    // console.log('BYU', params)
    dispatch(`app/${appTypes.SET_APP_CODE_ACTION}`, params, { root: true })
  },
  // [types.GET_PAYMENT_STATUS_ACTION]: async ({ commit }, { paymentId }) => {
  //   if (paymentId) {
  //     axios
  //       .get('https://widget.mltest.site/yookassa/api/payment/', {
  //         params: {
  //           id: paymentId
  //         }
  //       })
  //       .then(response => {
  //         if (response?.status === 200) {
  //           let { data } = response
  //           commit(types.SET_YOOKASSA_PAYMENT, data)
  //         }
  //       })
  //   }
  // },
  [types.MAKE_PAYMENT_ACTION]: async (
    { state, rootGetters, rootState, commit },
    payload
  ) => {
    // const sitecode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const sessionid = rootGetters['app/getSessionId']
    const sertcardownerid = rootState?.owner?.owner?.Id
    const confirmationburn = state?.payment?.ConfirmationToken
    const { amount, description } = payload

    const data = {
      operator,
      token,
      sessionid,
      sertcardownerid,
      confirmationburn,
      amount,
      description
    }
    return YookassaService.createPayment(data).then(response => {
      const { data } = response
      // console.log(response)
      commit(types.SET_YOOKASSA_PAYMENT, data)
    })
  }
}
