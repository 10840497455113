import panelBurgerTypes from '@/store/panelBurger/types'
import { mapActions } from 'vuex'
import DocDialog from '@/components/Modal/Owner/DocDialog'
import Config from '@/config'

export default {
  methods: {
    ...mapActions('panelBurger', [panelBurgerTypes.GET_SITE_DOC_ACTION]),
    async docsDialog(docType) {
      try {
        const docs = await this[panelBurgerTypes.GET_SITE_DOC_ACTION]({
          Oferta: docType === 1,
          Privacy: docType === 2
        })
        const { OfertaText, PrivacyText } = docs
        if (docs) {
          const isMobile = this.$helper?.isMobile()
          let modalConfig
          if (!isMobile) {
            modalConfig = {
              ...Config?.defaultModal,
              width: 769,
              maxWidth: 769,
              scrollable: true,
              // height: '90vh',
              maxHeight: '90vh'
            }
          } else {
            modalConfig = {
              ...Config?.defaultModal,
              width: '100%',
              maxWidth: '100%',
              scrollable: true,
              height: 'auto',
              maxHeight: 'auto'
            }
          }

          this.$modal.show(
            DocDialog,
            {
              text: docType === 1 ? OfertaText : PrivacyText,
              title: docType === 1 ? 'Оферта' : 'Политика конфиденциальности',
              isScrollable: isMobile ? false : true
            },
            modalConfig
          )
        }
      } catch (e) {
        console.log('error', e)
      }
    }
  }
}
