import ApiBase from './_ApiBase'

export default {
  createPayment(data) {
    return ApiBase.post('api/yookassa/payment', data)
  },
  getSitePageStatusPayment(data) {
    return ApiBase.post('api/sertcard/SitePageStatusPayment', data)
  }
}
