import types from './types'
import BurgerService from '@/api/BurgerService'
import router from '@/router'

export default {
  [types.GET_SITE_BURGER_ACTION]: async ({
    commit,
    rootGetters,
    rootState
  }) => {
    const siteCode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    if (!operator || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_SITE_BURGER_ACTION: не определен один из параметров'
        }
      })
    }
    const data = {
      SiteCode: siteCode,
      Operator: operator,
      Token: token
    }
    const response = await BurgerService.getSiteBurger(data)
    if (
      response?.status === 200 &&
      !response?.data?.Message &&
      !response?.data?.ErrorCode
    ) {
      commit(types.SET_SITE_BURGER, response?.data)
      return Promise.resolve()
    }
    return Promise.reject('action GET_SITE_BURGER_ACTION error', response)
  },
  [types.GET_SITE_DOC_ACTION]: async ({ rootGetters, rootState }, payload) => {
    const siteCode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    if (!operator || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_SITE_DOC_ACTION: не определен один из параметров'
        }
      })
    }
    const data = {
      SiteCode: siteCode,
      Operator: operator,
      Token: token,
      ...payload
    }
    const response = await BurgerService.getSiteDoc(data)
    if (
      response?.status === 200 &&
      !response?.data?.Message &&
      !response?.data?.ErrorCode
    ) {
      // commit(types.SET_SITE_DOC, response?.data?.Docs)
      return Promise.resolve(response?.data)
    }
    return Promise.reject('action GET_SITE_DOC_ACTION error', response)
  },
  [types.GET_SITE_RULE_ACTION]: async ({ commit, rootGetters, rootState }) => {
    const siteCode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    if (!operator || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_SITE_RULE_ACTION: не определен один из параметров'
        }
      })
    }
    const data = {
      SiteCode: siteCode,
      Operator: operator,
      Token: token
    }
    const response = await BurgerService.getSiteRule(data)
    if (
      response?.status === 200 &&
      !response?.data?.Message &&
      !response?.data?.ErrorCode
    ) {
      commit(types.SET_SITE_RULE, response?.data?.Rules)
      return Promise.resolve()
    }
    return Promise.reject('action GET_SITE_RULE_ACTION error', response)
  },
  [types.GET_SITE_WORK_ACTION]: async ({ commit, rootGetters, rootState }) => {
    const siteCode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    if (!operator || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_SITE_WORK_ACTION: не определен один из параметров'
        }
      })
    }
    const data = {
      SiteCode: siteCode,
      Operator: operator,
      Token: token
    }
    const response = await BurgerService.getSiteWork(data)
    if (
      response?.status === 200 &&
      !response?.data?.Message &&
      !response?.data?.ErrorCode
    ) {
      commit(types.SET_SITE_WORK, response?.data?.Works)
      return Promise.resolve()
    }
    return Promise.reject('action GET_SITE_WORK_ACTION error', response)
  }
}
