import types from './types'
import OwnerService from '@/api/OwnerService'

export default {
  async [types.SEND_EMAIL_CERTIFICATE_ACTION](
    { rootState, rootGetters },
    email
  ) {
    try {
      const operator = rootState?.auth?.decodeJwt?.oper
      const siteCode = rootGetters['app/getSiteCode']
      const token = rootState?.auth?.decodeJwt?.token

      const request = {
        token,
        operator,
        email,
        siteCode
      }
      return await OwnerService.sendForEmail(request)
    } catch (e) {
      console.log('SEND_EMAIL_CERTIFICATE_ACTION', e)
    }
  },
  [types.GET_PAGE_OWNER_CONFIG_ACTION]: async (
    { rootGetters, rootState, commit },
    routeName
  ) => {
    const siteCode = rootGetters['app/getSiteCode']
    const theme = rootState?.app?.config?.Theme
    const pageType = rootGetters['app/getPageType'](routeName)
    const operator = rootState?.auth?.decodeJwt?.oper
    const request = { siteCode, theme, pageType, operator }
    const { data, status } = await OwnerService.getSitePageSertOwner(request)
    // console.log(data, status)
    if (status === 200 && !data?.Message && data?.ErrorCode === 0) {
      commit(types.SET_PAGE_OWNER_CONFIG, data)
    }
  },
  [types.CREATE_OR_UPDATE_FRINED_ACTION]: async (
    { rootGetters, rootState },
    { ownerFriendId, name, email, phone }
  ) => {
    const siteCode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const body = {
      siteCode,
      operator,
      token,
      actiontype: ownerFriendId ? 'update' : 'create',
      agreepersonaldata: true,
      gender: true,
      name,
      email,
      phone
    }
    if (ownerFriendId) {
      body.id = ownerFriendId
    }
    try {
      const { status, data } = await OwnerService.createOrUpdateOwnerOrFriend(
        body
      )
      if (status === 200 && !data?.Message && data?.ErrorCode === 0) {
        return data?.Id
      }
      return null
    } catch (e) {
      return null
    }
  },
  [types.CREATE_OR_UPDATE_OWNER_ACTION]: async (
    { state, rootGetters, rootState, commit },
    { name, email, phone }
  ) => {
    const siteCode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const ownerId = state?.owner
    const dd = {
      siteCode,
      operator,
      token,
      actiontype: ownerId ? 'update' : 'create',
      agreepersonaldata: true,
      gender: true,
      name,
      email,
      phone
    }
    if (ownerId) {
      dd.id = ownerId
    }
    try {
      const { status, data } = await OwnerService.createOrUpdateOwnerOrFriend(
        dd
      )
      if (status === 200 && !data?.Message && data?.ErrorCode === 0) {
        commit(types.SET_CERT_OWNER, data?.Id)
      }
      return data
    } catch (e) {
      console.log('CREATE_OR_UPDATE_OWNER_ACTION', e)
    }
  },
  [types.VERIFY_CERT_CARD_AND_PIN_ACTION]: async (
    { rootGetters, rootState },
    { sertCard, secretCode, phone }
  ) => {
    const sitecode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const partner = rootState?.auth?.decodeJwt?.partner
    const posCode = rootState?.auth?.decodeJwt?.pos
    const data = {
      sitecode,
      operator,
      token,
      partner,
      posCode,
      sertCard,
      secretCode,
      phone
    }
    return OwnerService.verifyCardAndPinCode(data)
      .then(response => {
        if (
          response?.status === 200 &&
          !response?.data?.Message &&
          response?.data?.ErrorCode === 0
        ) {
          return { success: true }
        } else {
          return { success: false, message: response?.data?.Message }
        }
      })
      .catch(error => {
        return { success: false, message: error }
      })
  }
  // [types.CREATE_CERT_OWNER_ACTION]: async (
  //   { rootState, commit, rootGetters },
  //   payload
  // ) => {
  //   const sitecode = rootGetters['app/getSiteCode']
  //   const operator = rootState?.auth?.decodeJwt?.oper
  //   const token = rootState?.auth?.decodeJwt?.token
  //   const { code } = payload
  //   const { phone } = payload
  //   const { email } = payload
  //   const { name } = payload
  //   const agreepersonaldata = true
  //   const gender = true

  //   const dataOwner = {
  //     sitecode,
  //     operator,
  //     token,
  //     name,
  //     email,
  //     phone,
  //     code,
  //     gender,
  //     agreepersonaldata
  //   }
  //   console.log('CREATE OWNER')
  //   return OwnerService.createOwner(dataOwner).then(response => {
  //     if (
  //       response?.status === 200 &&
  //       !response?.data?.Message &&
  //       response?.data?.ErrorCode === 0
  //     ) {
  //       commit(types.SET_CERT_OWNER, response?.data)
  //     }
  //   })
  // }
}
