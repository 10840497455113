<template>
  <div class="v-application d-block">
    <div class="ml-default-modal px-12 pb-12 ml-bg-white">
      <Support />
    </div>
  </div>
</template>

<script>
import Support from '@/components/Burger/Pages/Support'
export default {
  components: { Support }
}
</script>
