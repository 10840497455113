<template>
  <div class="v-application d-block">
    <div class="ml-default-modal px-12 py-12 ml-bg-white">
      <h1>{{ title }}</h1>
      <v-btn
        v-if="!isScrollable"
        class="ml-close-doc-dialog"
        icon
        @click.prevent="$emit('close')"
      >
        <i class="mdi mdi-close"></i>
      </v-btn>
      <div
        class=" text-left pr-2"
        :class="{
          'ml-document-scroll': isScrollable,
          'ml-document-scroll-mobile': !isScrollable
        }"
      >
        <pre style="white-space: pre-line">{{ text }}</pre>
        <!-- {{ text }} -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'title', 'isScrollable']
}
</script>
