<template>
  <div class="v-application">
    <div class="ml-default-modal px-12 py-8 ml-bg-white">
      <div class="d-flex flex-column ">
        <img
          v-if="isTelegram"
          width="100"
          class=" align-self-center"
          height="100"
          src="@/assets/img/default/social-icon-tg.svg"
          alt=""
        />
        <img
          v-if="isWhatsApp"
          width="100"
          class=" align-self-center"
          height="100"
          src="@/assets/img/default/social-icon-whatsapp.svg"
          alt=""
        />
        <img
          v-if="isViber"
          width="100"
          class=" align-self-center"
          height="100"
          src="@/assets/img/default/social-icon-viber.svg"
          alt=""
        />
        <div class="mt-4">
          <div class="ml-text-24-29-600 ml-text-black mb-3">
            {{ botType }}-бот, <br />
            который поможет:
          </div>
          <div class="ml-text-grey ml-text-18-36-600 mb-2">купить</div>
          <div class="ml-text-grey ml-text-18-36-600 mb-2">подарить</div>
          <div class="ml-text-grey ml-text-18-36-600 mb-2">узнать баланс</div>
          <div class="ml-text-grey ml-text-18-36-600 mb-2">
            подобрать лучшее предложение
          </div>
          <div class="ml-text-grey ml-text-18-36-600 mb-2">
            напомнить в нужное время
          </div>
          <div class="ml-text-grey3 ml-text-18-36-600 mb-5">
            и многое другое...
          </div>
          <div>
            <a href="#" @click.prevent="openBotUrl" class="ml-silver2-btn">
              Подписаться на бота
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['type', 'botUrl'],
  computed: {
    isTelegram() {
      return this.type === 'telegram'
    },
    isWhatsApp() {
      return this.type === 'whatsapp'
    },
    isViber() {
      return this.type === 'viber'
    },
    botType() {
      if (this.isTelegram) return 'Telegram'
      if (this.isViber) return 'Viber'
      if (this.isWhatsApp) return 'WhatsApp'
      return null
    }
  },
  methods: {
    openBotUrl() {
      if (this.botUrl) window.open(this.botUrl, '_blank')
      else this.$emit('close')
    }
  }
}
</script>
