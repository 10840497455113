import store from '@/store'
import appTypes from '@/store/app/types'

export default async (to, from, next) => {
  // Если конфиг для страницы Confirming отстуствует, получаем его прежде чем перейти на страницу
  if (!store?.state?.app?.pageConfirmConfig) {
    // console.log('CONFIRM GUARD AWAIT START')
    const pageType = store?.getters['app/getPageType'](to?.name)
    await store.dispatch(`app/${appTypes.GET_CONFIRM_PAGE_CONFIG}`, {
      pageType
    })
    // console.log('CONFIRM GUARD AWAIT END')
  }
  return next()
}
