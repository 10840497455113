export default {
  REMOVE_CERTIFICATE_ACTION: 'REMOVE_CERTIFICATE_ACTION',
  ADD_CERTIFICATE_ACTION: 'ADD_CERTIFICATE_ACTION',
  UPDATE_CERTIFICATE_ACTION: 'UPDATE_CERTIFICATE_ACTION',
  //Mutations
  SET_BASKET: 'SET_BASKET',
  ADD_CERTIFICATE: 'ADD_CERTIFICATE',
  UPDATE_CERTIFICATE: 'UPDATE_CERTIFICATE',
  CANCEL_REMOVE_CERTIFICATE: 'CANCEL_REMOVE_CERTIFICATE',
  REMOVE_CERTIFICATE: 'REMOVE_CERTIFICATE',
  SET_CURRENT_CERTIFICATE: 'SET_CURRENT_CERTIFICATE',
  CALL_CONFIRM_MODAL: 'CALL_CONFIRM_MODAL',
  SET_PREVIEW: 'SET_PREVIEW',
  UPDATE_BASKET_ITEM_DELIVERY_DATETIME: 'UPDATE_BASKET_ITEM_DELIVERY_DATETIME',
  UPDATE_BASKET_ITEM_RECIPIENT: 'UPDATE_BASKET_ITEM_RECIPIENT'
}
