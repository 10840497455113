export default {
  // GET_SUBSCRIPTION_PAGE_CONFIG: 'GET_SUBSCRIPTION_PAGE_CONFIG',
  SET_FRIEND_DELIVERY_DATETIME_ACTION: 'SET_FRIEND_DELIVERY_DATETIME_ACTION',
  SET_FRIEND_RECIPIENT_ACTION: 'SET_FRIEND_RECIPIENT_ACTION',
  CREATE_OR_UPDATE_ORDER_ACTION: 'CREATE_OR_UPDATE_ORDER_ACTION',

  // SET_SUBSCRIPTION_PAGE_CONFIG: 'SET_SUBSCRIPTION_PAGE_CONFIG',
  SET_FRIEND_RECIPIENT: 'SET_FRIEND_RECIPIENT',
  SET_FRIEND_DELIVERY_DATETIME: 'SET_FRIEND_DELIVERY_DATETIME',
  SET_FRIEND: 'SET_FRIEND',
  SET_ORDER: 'SET_ORDER'
}
