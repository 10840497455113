import types from './types'
import Constatns from '@/helpers/constants'
import router from '@/router'
import burgerTypes from '@/store/panelBurger/types'
import panelBurgerTypes from '@/store/panelBurger/types'
import { HOME_PAGE } from '@/helpers/const/widgetPage'

export default {
  namespaced: true,
  state: {
    backUrl: null,
    command: null
  },
  actions: {
    [types.GO_BACK]({ state, commit }) {
      const { backUrl, command } = state
      if (!backUrl && !command) {
        commit(
          `panelBurger/${burgerTypes.CURRENT_BURGER_PAGE_SET}`,
          HOME_PAGE,
          {
            root: true
          }
        )
        return
      }
      console.log('not null')
      if (backUrl) {
        router.push(backUrl)
      }
      if (command) {
        switch (command) {
          case Constatns?.NAV_COMMANDS?.CLOSE_BURGER:
            commit(
              `panelBurger/${panelBurgerTypes.TOGGLE_PANEL_BURGER}`,
              {},
              { root: true }
            )
            break
        }
      }
    }
  },
  mutations: {
    [types.SET_BACK_URL](state, url) {
      state.backUrl = url
    },
    [types.SET_COMMAND](state, command) {
      state.command = command
    }
  }
}
