import Vue from 'vue'

export default {
  /**
   * Получает JWT токен либо из LocalStorage либо из хранилища
   * @param {Object} state
   */
  getJwtToken: state => {
    const token = state?.auth?.accessToken ?? Vue.auth.getAccessToken()
    return token
  }
}
