import types from './types'
import basketTypes from '@/store/basket/types'
import ownerTypes from '@/store/owner/types'
import OrderService from '@/api/OrderService'
// import ErrorService from '@/helpers/services/errorService'
import { addMinutes, format, parseISO } from 'date-fns'

export default {
  [types.CREATE_OR_UPDATE_ORDER_ACTION]: async ({
    state,
    rootGetters,
    rootState,
    commit
  }) => {
    const siteCode = rootGetters['app/getSiteCode']
    const sessionId = rootGetters['app/getSessionId']
    const operator = rootState?.auth?.decodeJwt?.oper
    const basket = rootState?.basket?.basket
    const token = rootState?.auth?.decodeJwt?.token
    const orderId = state?.order?.OrderId
    const orderStatus = 1
    const ownerId = rootState?.owner?.owner
    const ownerChannel = rootState?.verificationCode?.contacts?.channel
    const orderNumber = format(Date.now(), 'dd-MM-yyyy HH:mm:ss')
    const data = {
      siteCode,
      operator,
      token,
      sessionId,
      orderStatus,
      Sertcardownerid: ownerId,
      orderNumber
    }
    data.ActionType = !orderId ? 'Create' : 'Update'
    if (orderId) {
      data.OrderId = orderId
    }
    const basketItems = basket.map((item, index) => {
      const price = item?.price
      const count = item?.count
      const amount = price * count
      const date = item?.friend?.date
      let time = item?.friend?.time?.timeForApi
      let deliveryTime
      if (!time) {
        time = format(addMinutes(Date.now(), 10), 'HH:mm')
      }
      if (date) {
        deliveryTime = format(parseISO(`${date} ${time}`), 'dd.MM.yyyy HH:mm')
      }
      return {
        Id: index + 1,
        Name: item?.certificate?.name,
        Quantity: item?.count,
        Price: item?.price,
        Amount: amount,
        Image: item?.certificate?.img,
        Congratulation: item?.congratulation,
        CommunicationType: item?.friend?.channel ?? ownerChannel,
        Sertownerid: item?.friend?.friendId ?? ownerId,
        SendmessageTime: deliveryTime
      }
    })
    data.SertCardItems = basketItems
    console.log(data)
    const response = await OrderService.createOrUpdateOrder(data)
    if (
      response?.status === 200 &&
      !response?.data?.Message &&
      response?.data?.ErrorCode === 0
    ) {
      commit(types.SET_ORDER, response?.data)
    } else {
      console.error('CREATE_OR_UPDATE_ORDER_ACTION', response)
    }
  },
  [types.SET_FRIEND_RECIPIENT_ACTION]: async (
    { commit, dispatch },
    payload
  ) => {
    const { id, remote } = payload
    let friendId
    if (remote === true) {
      friendId = await dispatch(
        `owner/${ownerTypes.CREATE_OR_UPDATE_FRINED_ACTION}`,
        payload,
        { root: true }
      )
    }
    if (!id) {
      commit(types.SET_FRIEND_RECIPIENT, { ...payload, friendId })
    } else {
      commit(`basket/${basketTypes.UPDATE_BASKET_ITEM_RECIPIENT}`, payload, {
        root: true
      })
    }
  },
  [types.SET_FRIEND_DELIVERY_DATETIME_ACTION]: (
    { commit, rootState },
    payload
  ) => {
    const { id } = payload
    if (!id) {
      commit(types.SET_FRIEND_DELIVERY_DATETIME, payload)
    } else {
      commit(
        `basket/${basketTypes.UPDATE_BASKET_ITEM_DELIVERY_DATETIME}`,
        payload,
        { root: true }
      )
    }
  }
  /**
   * Получение настроек страницы оформления заказа
   */
  // [types.GET_SUBSCRIPTION_PAGE_CONFIG]: async ({
  //   rootState,
  //   commit,
  //   rootGetters
  // }) => {
  //   console.log('GET_SUBSCRIPTION_PAGE_CONFIG START')
  //   const theme = rootState?.app?.config?.Theme
  //   const siteCode = rootGetters['app/getSiteCode']
  //   const operator = rootState?.auth?.decodeJwt?.oper
  //   const token = rootState?.auth?.decodeJwt?.token
  //   if (!operator || !theme || !siteCode || !token) {
  //     return ErrorService.throw(
  //       'GET_SUBSCRIPTION_PAGE_CONFIG: не определен один из параметров'
  //     )
  //   }
  //   const data = {
  //     Theme: theme,
  //     SiteCode: siteCode,
  //     Operator: operator,
  //     Token: token
  //   }
  //   return ConfigService.fetchOrderPageConfig(data)
  //     .then(response => {
  //       if (response?.status === 200) {
  //         commit(types.SET_SUBSCRIPTION_PAGE_CONFIG, response?.data)
  //       } else {
  //         throw new Error(
  //           'Получение настроек страницы "Заказ" завершилось ошибкой'
  //         )
  //       }
  //     })
  //     .catch(error => {
  //       ErrorService.throw(`GET_SUBSCRIPTION_PAGE_CONFIG: ${error}`)
  //     })
  // }
}
