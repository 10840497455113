import AuthService from '@/api/AuthService'
import store from '@/store'

export default async (to, from, next) => {
  // console.log('AUTH GUARD')
  const noGuard = to?.meta?.noGuard
  if (noGuard) return next()
  const accessToken = store?.state?.auth?.accessToken
  const refreshToken = store?.state?.auth?.refreshToken
  // Определяем наличие accessToken и refreshToken в хранилище
  // если их нет вызываем метод авторизации (либо из Local Storage, либо через АПИ)
  if (!accessToken || !refreshToken) {
    try {
      await AuthService.authManager()
    } catch (e) {
      console.log('AUTH GUARD CATCH', e)
      return next({ name: 'Error', params: { message: e } })
    }
  }

  // // Определяем сколько настроек в объекте config, если 1 поле, то считает конфиг не инициализированным и запрашиваем его с сервера
  // const configFieldsCount = Object.keys(store.state.app.config).length
  // if (configFieldsCount <= 1) {
  //   await getSiteConfig()
  // }

  return next()
}
