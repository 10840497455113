import store from '@/store'
import widgetRoutes from '@/helpers/const/widgetRoutes'
import { NOT_WIDGET_ROUTE, NOT_CONFIG_ROUTE } from '@/helpers/const/errors'

const ROUTE_NAME_ACCESS_DENIED = 'AccessDenied'

export default async (to, from, next) => {
  // console.log('ROUTE GUARD')
  const routeName = to?.name?.toLowerCase()
  const routePath = to?.path?.toLowerCase()
  const routeFromName = from?.name
  //Ищем путь куда хотим перейти в списке всех путей
  const findWidgetRoute = widgetRoutes.find(
    route =>
      route?.routeName?.toLowerCase() === routeName && route.path === routePath
  )

  //Если этого пути не существует, то выдаем страницу доступ запрещен
  if (!findWidgetRoute) {
    console.log('001')
    if (
      routeFromName &&
      routeFromName.toLowerCase() !== ROUTE_NAME_ACCESS_DENIED.toLowerCase()
    ) {
      console.log('REDIREC 222')
      return next({ name: 'AccessDenied', params: { type: NOT_WIDGET_ROUTE } })
    }
  }
  //Ищем путь куда хотим перейти в списке всех путей конфига, пришедшего с сервера
  // console.log('routeGuard', store?.state?.app?.config?.PageConfig)
  const findConfigRoute = store?.state?.app?.config?.PageConfig?.find(
    route =>
      route?.Name?.toLowerCase() === findWidgetRoute?.configName?.toLowerCase()
  )
  //Если этого пути не существует, то выдаем страницу доступ запрещен
  if (!findConfigRoute) {
    console.log('002')
    if (
      routeFromName &&
      routeFromName.toLowerCase() !== ROUTE_NAME_ACCESS_DENIED.toLowerCase()
    ) {
      console.log('REDIREC 333')
      return next({ name: 'AccessDenied', params: { type: NOT_CONFIG_ROUTE } })
    }
  }
  // console.log('ROUTE GUARD END')
  return next()
}
