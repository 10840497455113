export default {
  jwtu: 'unksite',
  jwtp: 'unksite',
  defaultModal: {
    height: 'auto',
    maxWidth: 400,
    adaptive: true,
    transition: 'nice-modal-fade',
    clickToClose: true
  }
}
