import ApiBase from './_ApiBase'
import authTypes from '../store/auth/types'
import store from '../store'
import Vue from 'vue'
import config from '@/config'

export default {
  /**
   * Запускает принудительную авторизацию через АПИ, без проверки ключей в LS
   */
  authManagerForce() {
    const user = config?.jwtu
    const password = config?.jwtp
    if (!user || !password)
      throw new Error('AUTH_MANAGER_FORCED: JWT USER NOT DEFINED')
    var data = new URLSearchParams()
    data.append('username', user)
    data.append('password', password)
    data.append('grant_type', 'password')
    return ApiBase.post('managerLogin', data).then(response => {
      if (response?.status === 200) {
        console.log('AUTH API')
        const accessToken = response?.data?.access_token
        const refreshToken = response?.data?.refresh_token
        if (accessToken && refreshToken) {
          store.commit(`auth/${authTypes.SET_REFRESH_TOKEN}`, refreshToken)
          store.commit(`auth/${authTypes.SET_ACCESS_TOKEN}`, accessToken)
          Vue.auth.setAccessToken(accessToken)
          Vue.auth.setRefreshToken(refreshToken)
        }
      }
    })
  },
  /**
   * Запускает авторизацию либо через LS, либо через АПИ
   */
  authManager() {
    const localAccessToken = Vue.auth.getAccessToken()
    const localRefreshToken = Vue.auth.getRefreshToken()
    if (localAccessToken && localRefreshToken) {
      // console.log('AUTH LS')
      store.commit(`auth/${authTypes.SET_ACCESS_TOKEN}`, localAccessToken)
      store.commit(`auth/${authTypes.SET_REFRESH_TOKEN}`, localRefreshToken)
      return Promise.resolve()
    } else {
      const user = config?.jwtu
      const password = config?.jwtp
      if (!user || !password)
        throw new Error('AUTH_MANAGER: JWT USER NOT DEFINED')
      var data = new URLSearchParams()
      data.append('username', user)
      data.append('password', password)
      data.append('grant_type', 'password')
      return ApiBase.post('managerLogin', data).then(response => {
        if (response?.status === 200) {
          // console.log('AUTH API')
          const accessToken = response?.data?.access_token
          const refreshToken = response?.data?.refresh_token
          if (accessToken && refreshToken) {
            store.commit(`auth/${authTypes.SET_REFRESH_TOKEN}`, refreshToken)
            store.commit(`auth/${authTypes.SET_ACCESS_TOKEN}`, accessToken)
            Vue.auth.setAccessToken(accessToken)
            Vue.auth.setRefreshToken(refreshToken)
          }
        }
      })
    }
  },
  refreshToken() {
    const refreshToken =
      // store?.state?.auth?.refreshToken ?? Vue.auth.getRefreshToken()
      Vue.auth.getRefreshToken()
    console.log('refreshToken', 1)
    if (refreshToken) {
      console.log('refreshToken', 2)
      let data = new URLSearchParams()
      data.append('grant_type', 'refresh_token')
      data.append('refresh_token', refreshToken)
      return ApiBase.post('managerLogin', data)
        .then(response => {
          if (response?.status === 200) {
            const accessToken = response?.data?.access_token
            const refreshToken = response?.data?.refresh_token
            if (accessToken && refreshToken) {
              store.commit(`auth/${authTypes.SET_REFRESH_TOKEN}`, refreshToken)
              store.commit(`auth/${authTypes.SET_ACCESS_TOKEN}`, accessToken)
              Vue.auth.setAccessToken(accessToken)
              Vue.auth.setRefreshToken(refreshToken)
              return Promise.resolve(response)
            } else {
              console.log('refreshToken RESPONSE DATA NOT FOUND')
              return Promise.reject('refreshToken RESPONSE DATA NOT FOUND')
            }
          } else {
            store.commit(`auth/${authTypes.SET_REFRESH_TOKEN}`, null)
            store.commit(`auth/${authTypes.SET_ACCESS_TOKEN}`, null)
            Vue.auth.logout()
            console.log('refreshToken RESPONSE STATUS IS NOT 200')
            return Promise.reject('refreshToken RESPONSE STATUS IS NOT 200')
          }
        })
        .catch(error => {
          console.log('refreshToken CATCH', error)
          store.commit(`auth/${authTypes.SET_REFRESH_TOKEN}`, null)
          store.commit(`auth/${authTypes.SET_ACCESS_TOKEN}`, null)
          Vue.auth.logout()
          return Promise.reject(error)
        })
    } else {
      console.log('ACCESS_TOKEN NOT DEFINED')
      return Promise.reject('ACCESS_TOKEN NOT DEFINED')
    }
  },
  logout() {
    store.commit(`auth/${authTypes.SET_USER}`, null)
  },
  test() {
    const data = new URLSearchParams()
    data.append('Operator', 3)
    var config = {
      headers: {
        Authorization: 'Bearer null'
      }
    }
    return ApiBase.post('api/site/GetCampaigns', data, config).then(x => {
      console.log(x)
    })
  }
}
