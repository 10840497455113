import types from './types'
import VerifyService from '@/api/VerifyService'
import router from '@/router'

export default {
  [types.REQUEST_CODE]: async (
    { rootState, rootGetters },
    { phone, communicationtype, codeType }
  ) => {
    const sitecode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const sessionid = rootGetters['app/getSessionId']
    const data = {
      sitecode,
      operator,
      token,
      sessionid,
      phone,
      communicationtype,
      codeType
    }
    return VerifyService.requestCode(data)
      .then(response => {
        if (
          response?.status === 200 &&
          !response?.data?.Message &&
          response?.data?.ErrorCode === 0
        ) {
          return Promise.resolve()
        } else {
          router.push({
            name: 'Error',
            params: { message: 'Ошибка при отправке кода на телефон' }
          })
        }
      })
      .catch(error => {
        router.push({
          name: 'Error',
          params: {
            message: 'Ошибка при отправке кода на телефон',
            subMessage: error
          }
        })
      })
  },
  [types.SEND_VERIFICATIONCODE_VIA_SMS]: async (
    { rootState, commit, rootGetters },
    payload
  ) => {
    const sitecode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const communicationtype = 1
    const { code } = payload
    const { phone } = payload

    const data = {
      sitecode,
      operator,
      token,
      phone,
      communicationtype,
      code
    }
    commit(types.INIT_VERIFICATION_CODE_PROCESS, true)
    try {
      const resultVerify = await VerifyService.sendCode(data)
      setTimeout(() => commit(types.INIT_VERIFICATION_CODE_PROCESS, false), 500)
      if (
        resultVerify?.status === 200 &&
        !resultVerify?.data?.Message &&
        resultVerify?.data?.ErrorCode === 0
      ) {
        return Promise.resolve(true)
      } else {
        return Promise.resolve(false)
      }
    } catch (e) {
      console.error('ERROR:SEND_VERIFICATIONCODE_VIA_SMS', e)
      setTimeout(() => commit(types.INIT_VERIFICATION_CODE_PROCESS, false), 500)
      return Promise.resolve(false)
    }
  }
  // [types.SEND_VERIFICATIONCODE_VIA_MESSANGER]: async (
  //   { rootState, commit, rootGetters },
  //   payload
  // ) => {
  //   const sitecode = rootGetters['app/getSiteCode']
  //   const operator = rootState?.auth?.decodeJwt?.oper
  //   const token = rootState?.auth?.decodeJwt?.token
  //   const communicationtype = 1
  //   const { code } = payload
  //   const { phone } = payload
  //   const data = {
  //     sitecode,
  //     operator,
  //     token,
  //     phone,
  //     communicationtype,
  //     code
  //   }
  //   commit(types.INIT_VERIFICATION_CODE_PROCESS, true)
  //   try {
  //     const resultVerify = await VerifyService.sendCode(data)
  //     setTimeout(() => commit(types.INIT_VERIFICATION_CODE_PROCESS, false), 500)
  //     if (
  //       resultVerify?.status === 200 &&
  //       !resultVerify?.data?.Message &&
  //       resultVerify?.data?.ErrorCode === 0
  //     ) {
  //       return Promise.resolve(true)
  //     }
  //     return Promise.resolve(false)
  //   } catch (e) {
  //     console.error('ERROR:SEND_VERIFICATIONCODE_VIA_MESSANGER', e)
  //     setTimeout(() => commit(types.INIT_VERIFICATION_CODE_PROCESS, false), 500)
  //     return Promise.resolve(false)
  //   }
  // }
}
