<template>
  <div class="w100 text-center">
    <canvas id="ml-owner-drawing-card"></canvas>
    <img class="d-none" ref="barcode-img" :src="barcodeImgSrc" alt="" />
  </div>
</template>

<script>
const CANVAS_WIDTH = 420
const CANVAS_HEIGHT = 520
const CARD_BARCODE_HEIGHT = 150

// import VueBarcode from 'vue-barcode'

export default {
  props: {
    cardImage: {
      type: String,
      default: '@/assets/img/example/riv-gosh-card.png'
    },
    cardNumber: {
      type: String,
      default: '1234567890'
    },
    cardPinCode: {
      type: String,
      default: '678 579'
    }
  },
  // components: {
  //   barcode: VueBarcode
  // },
  data: () => ({
    cardImageHeight: 0,
    barcodeImg: null
  }),
  mounted() {
    this.setInitialize()
  },
  computed: {
    context() {
      return document?.getElementById('ml-owner-drawing-card')?.getContext('2d')
    },
    barcodeImgSrc() {
      return null
    }
  },
  methods: {
    drawBarcode() {
      const context = this.context
      const barcodeImage = new Image()
      barcodeImage.src = this.$refs['barcode-img']?.src

      barcodeImage.onload = () => {
        const imageH = barcodeImage.height
        const imageW = barcodeImage.width

        let scale = 1

        if (imageW > CANVAS_WIDTH) {
          scale = Math.round(imageW / CANVAS_WIDTH, 0) + 0.5
        }

        const dWidth = imageW / scale
        const dHeight = imageH / scale

        const startX = (CANVAS_WIDTH - dWidth) / 2
        const startY = this.cardImageHeight
        // context.save()
        // this.roundedImage(context, startX, 30, dWidth, dHeight, 10)
        // context.clip()
        context.drawImage(barcodeImage, startX, startY, dWidth, dHeight)

        // context.restore()
      }

      // let cardImage = new Image(this.$refs.barcode)
      // cardImage.onload = () => {
      //   console.log('svg loaded')
      // }
    },
    drawCardImage() {
      const context = this.context
      let cardImage = new Image()
      // cardImage.src = 'https://cdn.wallpapersafari.com/85/47/LIbvrV.jpg'
      // cardImage.src = 'https://vidget.lctest.ru/img/example/riv-gosh-card.png'
      cardImage.src = require('@/assets/img/example/riv-gosh-card.png')
      // cardImage.src = require(this.cardImage)
      cardImage.onload = () => {
        const imageH = cardImage.height
        const imageW = cardImage.width

        let scale = 1

        if (imageW > CANVAS_WIDTH) {
          scale = Math.round(imageW / CANVAS_WIDTH, 0) + 0.5
        }

        const dWidth = imageW / scale
        const dHeight = imageH / scale

        const startX = (CANVAS_WIDTH - dWidth) / 2

        context.save()
        this.roundedImage(context, startX, 30, dWidth, dHeight, 10)
        context.clip()
        context.drawImage(cardImage, startX, 30, dWidth, dHeight)
        context.restore()
        this.$set(this, 'cardImageHeight', dHeight + 60)
        this.drawBarcode()
        this.drawCardNumber()
        this.drawCardPinCode()
      }
    },
    drawCardPinCode() {
      var ctx = this.context
      ctx.font = '500 18px Inter'
      // ctx.fontWeight = '500'
      ctx.fillStyle = '#000000'
      ctx.textAlign = 'center'
      ctx.fillText(
        `PIN-код: ${this.cardPinCode}`,
        CANVAS_WIDTH / 2,
        this.cardImageHeight + CARD_BARCODE_HEIGHT + 65
      )
    },
    drawCardNumber() {
      var ctx = this.context
      ctx.font = '18px Inter'
      ctx.fillStyle = '#808080'
      ctx.textAlign = 'center'
      ctx.fillText(
        `Номер карты: ${this.cardNumber}`,
        CANVAS_WIDTH / 2,
        this.cardImageHeight + CARD_BARCODE_HEIGHT + 15
      )
    },
    setInitialize() {
      const canvas = document.getElementById('ml-owner-drawing-card')
      canvas.width = CANVAS_WIDTH
      canvas.height = CANVAS_HEIGHT

      const context = this.context
      context.fillStyle = 'white'
      context.fillRect(0, 0, canvas.width, canvas.height)
      this.getSvgSrc()
      this.drawCardImage(canvas)
    },
    getSvgSrc() {
      // setTimeout(() => {
      const imgBarcode = this.$refs['barcode-img']
      const svgBarcode = document.getElementsByClassName(
        'vue-barcode-element'
      )[0]

      // prettier-ignore
      const xml = (new XMLSerializer).serializeToString(svgBarcode)

      // imgBarcode.src = 'data:image/svg+xml;charset=utf-8,' + btoa(xml)
      imgBarcode.src = 'data:image/svg+xml;base64,' + btoa(xml)
      // console.log(svgBarcode)
      // console.log(imgBarcode)
      // }, 1000)

      // var svg = document.querySelector('svg'),
      // img = document.querySelector('img');
      // setImageToSVG(img,svg);
      // function setImageToSVG(img,svg){
      // var xml = (new XMLSerializer).serializeToString(svg);
      // img.src = "data:image/svg+xml;charset=utf-8,"+xml;
      // }​
    },
    roundedImage(ctx, x, y, width, height, radius) {
      ctx.beginPath()
      ctx.moveTo(x + radius, y)
      ctx.lineTo(x + width - radius, y)
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
      ctx.lineTo(x + width, y + height - radius)
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      )
      ctx.lineTo(x + radius, y + height)
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
      ctx.lineTo(x, y + radius)
      ctx.quadraticCurveTo(x, y, x + radius, y)
      ctx.closePath()
    }
  }
}
</script>
