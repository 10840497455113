import store from '@/store'
import appTypes from '@/store/app/types'

export default async (to, from, next) => {
  // Если конфиг для страницы Contacts отстуствует, получаем его прежде чем перейти на страницу
  if (!store?.state?.app?.pageContactConfig) {
    await store.dispatch(`app/${appTypes.GET_CONTACT_PAGE_CONFIG}`)
  }
  return next()
}
