<template>
  <div class="pb" style="margin-top: -60px">
    <dir class="row">
      <div class="col-12">
        <ul class="home-menu">
          <li v-if="hasBurgerRulesRule">
            <a href="#" @click.prevent="goRules">Правила</a>
          </li>
          <li v-if="hasBurgerWorkRule">
            <a href="#" @click.prevent="goHowItsWork">Как это работает</a>
          </li>
          <li v-if="hasBurgerFaqRule">
            <a href="#" @click.prevent="goFAQ">Частые вопросы</a>
          </li>
          <li v-if="hasBurgerOfferRule">
            <a href="#" @click.prevent="goOffer">Оферта</a>
          </li>
          <li v-if="hasBurgerHelpRule">
            <a href="#" @click.prevent="goSupport">Поддержка</a>
          </li>
        </ul>
        <hr v-if="showContacts" class="ml-delimeter" />
      </div>
      <section v-if="showContacts">
        <div class="col-12" v-if="logo">
          <img class="logo" :src="logo" alt="" />
        </div>
        <!-- <div class="col-12"> -->
        <!-- <div class="text5 mb-3">8 (800) 333-20-201</div> -->
        <!-- <div class="text5 mb-3">info@daritlegko.ru</div> -->
        <!-- <div class="text5 mb-3" v-if="site">{{ site }}</div> -->
        <!-- </div> -->
        <div class="col-12">
          <div class="d-flex ">
            <!-- <a href="#" class="mloyalty-social-btn">
              <img src="~@/assets/img/default/social-icon-ok.png" alt="" />
            </a> -->
            <a :href="fb" target="_blank" class="mloyalty-social-btn" v-if="fb">
              <i class="mdi mdi-facebook" style="color: #808080;"></i>
            </a>

            <a :href="tg" target="_blank" class="mloyalty-social-btn" v-if="tg">
              <img
                src="~@/assets/img/default/social-icon-tg.svg"
                width="18"
                alt=""
              />
            </a>

            <a
              :href="insta"
              target="_blank"
              class="mloyalty-social-btn"
              v-if="insta"
            >
              <i class="mdi mdi-instagram" style="color: #808080;"></i>
            </a>

            <a
              :href="viber"
              target="_blank"
              class="mloyalty-social-btn"
              v-if="viber"
            >
              <img
                src="~@/assets/img/default/social-icon-viber.svg"
                width="18"
                alt=""
              />
            </a>
            <a
              :href="whatsapp"
              target="_blank"
              class="mloyalty-social-btn"
              v-if="whatsapp"
            >
              <img
                src="~@/assets/img/default/social-icon-whatsapp.svg"
                width="18"
                alt=""
              />
            </a>

            <a :href="vk" target="_blank" class="mloyalty-social-btn" v-if="vk">
              <img src="~@/assets/img/default/social-icon-vk.png" alt="" />
            </a>
          </div>
        </div>
      </section>
    </dir>
  </div>
</template>

<script>
const SERT_OWNER_LOGO = 'logo_footer'
const SERT_OWNER_SITE = 'website'
const SERT_OWNER_GROUP_INSTA = 'group_insta'
const SERT_OWNER_BOT_TG = 'bot_telega'
const SERT_OWNER_BOT_FB = 'bot_fb'
const SERT_OWNER_BOT_VIBER = 'bot_viber'
const SERT_OWNER_BOT_WHATSAPP = 'bot_whatsapp'
const SERT_OWNER_BOT_VK = 'bot_vk'

import panelBurgerTypes from '@/store/panelBurger/types'
import MixinChagePage from '@/helpers/mixins/burger/changePage'
import {
  FAQ_PAGE,
  HOW_ITS__WORK,
  SUPPORT,
  OFFER,
  RULES
} from '@/helpers/const/widgetPage'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'Home-Page',
  mixins: [MixinChagePage],
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('app', [
      'hasBurgerWorkRule',
      'hasBurgerHelpRule',
      'hasBurgerFaqRule',
      'hasBurgerOfferRule',
      'hasBurgerRulesRule'
    ]),
    ...mapState({
      showContacts: state => state?.panelBurger?.pageBurger?.SertOwnerContacts,
      contacts: state => state?.app?.config?.SertOwner
    }),
    site() {
      return this.getSertOwnerParam(SERT_OWNER_SITE)
    },
    logo() {
      return this.getSertOwnerParam(SERT_OWNER_LOGO)
    },
    vk() {
      return this.getSertOwnerParam(SERT_OWNER_BOT_VK)
    },
    insta() {
      return this.getSertOwnerParam(SERT_OWNER_GROUP_INSTA)
    },
    whatsapp() {
      return this.getSertOwnerParam(SERT_OWNER_BOT_WHATSAPP)
    },
    viber() {
      return this.getSertOwnerParam(SERT_OWNER_BOT_VIBER)
    },
    tg() {
      return this.getSertOwnerParam(SERT_OWNER_BOT_TG)
    },
    fb() {
      return this.getSertOwnerParam(SERT_OWNER_BOT_FB)
    }
  },
  mounted() {
    // this.setInitialize()
  },
  methods: {
    ...mapActions('panelBurger', [panelBurgerTypes.GET_SITE_BURGER_ACTION]),
    getSertOwnerParam(param) {
      if (!this.contacts) return null
      const findSite = this.contacts?.find(x => x?.Name === param)
      if (findSite && findSite?.Shown) return findSite?.Value
      return null
    },
    // async setInitialize() {
    //   this.loading = true
    //   try {
    //     await this[panelBurgerTypes.GET_SITE_BURGER_ACTION]()
    //     this.loading = false
    //   } catch (e) {
    //     console.log('error setInitialize HOME PAGE OF BURGER', e)
    //   }
    // },
    goFAQ() {
      this.changePanelBurgerPage(FAQ_PAGE)
    },
    goHowItsWork() {
      this.changePanelBurgerPage(HOW_ITS__WORK)
    },
    goOffer() {
      this.changePanelBurgerPage(OFFER)
    },
    goSupport() {
      this.changePanelBurgerPage(SUPPORT)
    },
    goRules() {
      this.changePanelBurgerPage(RULES)
    }
  }
}
</script>
