import Vue from 'vue'
import axios from 'axios'
import AuthService from './AuthService'

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: 'https://api.loycon.ru/WebService/UNK/unklcmanagerpartner/',
  headers: {}
})

Axios.interceptors.request.use(
  config => {
    //TODO - удалить это условие для проверки refresh_token
    // if (config.url !== 'api/site/GetCampaigns')
    config.headers.Authorization = 'Bearer ' + Vue.auth.getAccessToken()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log('AXIOS ERROR 1', error)
    if (error?.response?.status === 401) {
      console.log('AXIOS ERROR 401')
      return AuthService.authManagerForce()
        .then(() => {
          console.log('SUCCESS REAUTH')
          return Axios.request(error.config)
        })
        .catch(error => {
          console.log('CATCH REAUTH, ERROR FORCED AUTH', error)
          return Promise.reject(error)
        })

      return AuthService.refreshToken()
        .then(response => {
          console.log('AFTER SUCCESS CALL REFRESH', response)
          if (response?.status === 200 || response?.status === 201) {
            console.log('REFRESH STATUS 200')
            // 1) put token to LocalStorage
            // localStorageService.setToken(response.data)

            // 2) Change Authorization header
            const accessToken = response?.data?.access_token
            error.config.headers['Authorization'] = `Bearer ${accessToken}`
            // error.config.baseURL = undefined

            // 3) return originalRequest object with Axios.
            return Axios.request(error.config)
          }
        })
        .catch(error => {
          AuthService.authManagerForce()
            .then(response => {
              console.log('CATCH RT, SUCCESS FORCED AUTH', response)
              return Axios.request(error.config)
            })
            .catch(error => {
              console.log('CATCH RT, ERROR FORCED AUTH', error)
              return Promise.reject(error)
            })
        })
    }
    return Promise.reject(error)
  }
)

export default Axios
