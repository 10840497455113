<template>
  <div class="ml-default-modal">
    <div>
      <v-text-field
        color="dark"
        append-icon="mdi-check"
        type="email"
        required
        :rules="emailRules"
        height="60"
        label="Ваш e-mail*"
        class="ml-input ml-hide-details"
        autocomplete="off"
        :class="{ novalidate: validateEmail === false }"
        outlined
        v-model="email"
      ></v-text-field>

      <button
        :disabled="!validateEmail || loading"
        @click="sendEmail"
        class="ml-silver2-btn ml-silver2-btn__py20 w100"
      >
        <v-progress-circular
          v-if="loading"
          indeterminate
          width="2"
          size="20"
        ></v-progress-circular>
        Отправить
      </button>
    </div>
  </div>
</template>

<script>
import ownerTypes from '@/store/owner/types'
import { mapActions } from 'vuex'
import SuccessDialog from '@/components/Modal/SuccessDialog'
import Config from '@/config'

export default {
  data: () => ({
    // email: 'jusupovz@gmail.com',
    email: null,
    loading: false,
    emailRules: [
      v => !!v || 'Необходимо заполнить e-mail',
      v => /.+@.+/.test(v) || 'Введен некорректный e-mail'
    ]
  }),
  computed: {
    validateEmail() {
      return /.+@.+/.test(this.email)
    }
  },
  methods: {
    ...mapActions('owner', [ownerTypes.SEND_EMAIL_CERTIFICATE_ACTION]),
    async sendEmail() {
      try {
        this.loading = true
        await this[ownerTypes.SEND_EMAIL_CERTIFICATE_ACTION](this.email)
        this.loading = false
        this.$emit('close')
        this.closedEmailDialog()
      } catch (e) {
        this.loading = false
        console.log('sendEmail error', e)
      }
    },
    closedEmailDialog() {
      this.$modal.show(
        SuccessDialog,
        { text: `Сертификат отправлен на e-mail <br /> ${this.email}` },
        {
          ...Config?.defaultModal,
          width: 384
        }
      )
    }
  }
}
</script>
