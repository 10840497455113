// prettier-ignore
export default [
  { id: 1, configName: 'Order', path: '/', routeName: 'Order' },
  { id: 2, configName: 'Preview', path: '/preview-mobile', routeName: 'PreviewMobile' },
  { id: 3, configName: 'Contacts', path: '/sending', routeName: 'Contacts' },
  { id: 4, configName: 'Basket', path: '/basket', routeName: 'Basket' },
  { id: 5, configName: 'Orderconfirm', path: '/confirming', routeName: 'Confirming' },
  { id: 6, configName: 'Payment', path: '/yookassa', routeName: 'PaymentYookassa' },
  { id: 7, configName: 'Statuspayment', path: '/payment-result', routeName: 'PaymentResult' },
  // { id: 8, configName: 'Burger', path: '/8', routeName: 'Order' },
  // { id: 9, configName: 'Burger_work', path: '/9', routeName: 'Order' },
  // { id: 10, configName: 'Burger_faq', path: '/10', routeName: 'Order' },
  // { id: 11, configName: 'Burger_offer', path: '/11', routeName: 'Order' },
  // { id: 12, configName: 'Burger_help', path: '/12', routeName: 'Order' },
  { id: 13, configName: 'Sertowner', path: '/13', routeName: 'Order' },
  { id: 14, configName: 'Animation', path: '/14', routeName: 'Order' },
  { id: 15, configName: 'Balance_verify', path: '/balance-verify', routeName: 'BalanceVerify' },
  { id: 16, configName: 'Balance_shown', path: '/balance', routeName: 'Balance' },
  { id: 17, configName: 'Subscription', path: '/subscription', routeName: 'Subscription' },
  { id: 18, configName: 'Sertowner', path: '/18', routeName: 'CertificateOwnerHash' },
]
